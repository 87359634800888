<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <div class="form-wrapper btb-garden-db">
            <b-overlay :show="loading">
                <b-card :title="$t('teaGardenConfig.garden_registration')">
                </b-card>
                <b-row>
                    <b-col sm="6">
                        <b-card :sub-title="$t('teaGardenDashboard.new_application')">
                            <b-card-text>
                                <b-row>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.applicant_list', query: { service_id: 1, status: 0, application_type: 1, limit: 100 } }">
                                        <div class="info-card total-app">
                                            <div class="content">
                                                <p>
                                                {{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}
                                                </p>
                                                <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                                <h3>{{ $n(totalInfo.newApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-file-3-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.approved_list', query: { service_id: 1, status: 6, application_type: 1, limit: 100 } }">
                                        <div class="info-card approved">
                                            <div class="content">
                                                <p>{{ $t('globalTrans.approved') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                                <h3>{{ $n(totalInfo.newApprovedApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.applicant_list', query: { service_id: 1, status: 2, application_type: 1, limit: 100 } }">
                                        <div class="info-card pending mt-3">
                                            <div class="content">
                                                <p>{{ $t('globalTrans.pending') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                                <h3>{{ $n(totalInfo.newPendingApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-time-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                         <router-link :to="{ name: 'tea_garden_service.approval-process.rejected_list', query: { service_id: 1, status: 7, limit: 100 } }">
                                        <div class="info-card reject mt-3">
                                            <div class="content">
                                                <p>{{ $t('globalTrans.rejected') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small> &nbsp; </small> -->
                                                <h3>{{ $n(totalInfo.rejectedApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-close-line"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col sm="6">
                        <b-card :sub-title="$t('teaGardenDashboard.re_new_application')">
                            <b-card-text>
                                <b-row>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.applicant_list', query: { service_id: 1, status: 0, application_type: 2, limit: 100 } }">
                                        <div class="info-card total-app">
                                            <div class="content">
                                                <p>{{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                                <h3>{{ $n(totalInfo.renewApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-file-3-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.approved_list', query: { service_id: 1, status: 6, application_type: 2, limit: 100 } }">
                                        <div class="info-card approved">
                                            <div class="content">
                                                <p>{{ $t('globalTrans.approved') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                                <h3>{{ $n(totalInfo.renewApprovedApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                        <router-link :to="{ name: 'tea_garden_service.approval-process.applicant_list', query: { service_id: 1, status: 2, application_type: 2, limit: 100 } }">
                                        <div class="info-card pending mt-3">
                                            <div class="content">
                                                <p>{{ $t('globalTrans.pending') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                                <h3>{{ $n(totalInfo.renewPendingApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-time-fill"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                            <router-link :to="{ name: 'tea_garden_service.approval-process.applicant_list', query: { service_id: 1, status: 0, expired_date: new Date().toJSON().slice(0, 10), limit: 100 } }">
                                        <div class="info-card reject mt-3">
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.expired') }} {{ $t('globalTrans.application') }}</p>
                                                <!-- <small> &nbsp; </small> -->
                                                <h3>{{ $n(totalInfo.expiredApplication) }}</h3>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-close-line"></i>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <b-card>
                    <div id="chart">
                        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </b-card>
            </b-overlay>
        </div>
    </div>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    export default {
        name: 'TeaGardenRegistration',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                totalInfo: {
                    newApplication: 0,
                    newApprovedApplication: 0,
                    newPendingApplication: 0,
                    rejectedApplication: 0,
                    renewApplication: 0,
                    renewApprovedApplication: 0,
                    renewPendingApplication: 0,
                    expiredApplication: 0
                },
                newSeriesData: [],
                renewSeriesData: [],
                categories: [],
                categoriesBn: []
            }
        },
        created () {
            this.loadData()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            series () {
                return [{
                    name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.new')})`,
                    data: this.newSeriesData
                }, {
                    name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.renew')})`,
                    data: this.renewSeriesData
                }]
            },
            chartOptions () {
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '10%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.currentLocale === 'en' ? this.categories : this.categoriesBn
                    },
                    fill: {
                        opacity: 1
                    }
                }
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            async loadData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/tea-garden-registration')
                if (result.success) {
                    this.totalInfo = result.data.totalInfo
                    const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
                    const chartInfo = result.data.chartInfo.map(item => {
                        const fiscalYear = fiscalYearList.find(obj => obj.value === parseInt(item.fiscal_year_id))
                        if (fiscalYear) {
                                const customItem = {
                                fiscal_year_en: fiscalYear.text_en,
                                fiscal_year_bn: fiscalYear.text_bn,
                                sorting_order: fiscalYear.sorting_order
                            }
                            return Object.assign({}, item, customItem)
                        } else {
                            const customItem = {
                                fiscal_year_en: '',
                                fiscal_year_bn: '',
                                sorting_order: ''
                            }
                            return Object.assign({}, item, customItem)
                        }
                    }).sort((a, b) => a.sorting_order - b.sorting_order)
                    const categoryArr = []
                    const categoryBnArr = []
                    const newSeriesArr = []
                    const renewSeriesArr = []
                    chartInfo.forEach(item => {
                        categoryArr.push(item.fiscal_year_en)
                        categoryBnArr.push(item.fiscal_year_bn)
                        newSeriesArr.push(item.totalNewApplication)
                        renewSeriesArr.push(item.totalRenewApplication)
                    })
                    this.categories = categoryArr
                    this.categoriesBn = categoryBnArr
                    this.newSeriesData = newSeriesArr
                    this.renewSeriesData = renewSeriesArr
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            }
        }
    }
</script>
<style>
.btb-garden-db .card-subtitle{
    margin: .4rem 0 0 1rem;
}
.btb-garden-db .info-card {
    color: #15273B;
    padding: 1rem;
    border-radius: 8px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btb-garden-db .info-card .icon {
    display: flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.btb-garden-db .info-card .content {
    margin-top: 5px;
}

.btb-garden-db .info-card .content p {
    white-space: nowrap;
}
.btb-garden-db .info-card .content h3 {
    margin-top: 5px;
}

.btb-garden-db .info-card.approved {
    background: #DAF3F1;
}

.btb-garden-db .info-card.approved .icon,
.btb-garden-db .info-card.approved .content h3 {
    color: #11b75f;
}

.btb-garden-db .info-card.pending {
    background: #FEF6E1;
}

.btb-garden-db .info-card.pending .icon,
.btb-garden-db .info-card.pending .content h3 {
    color: #c78406;
}

.btb-garden-db .info-card.requested-far {
    background: #0fc9cf;
}

.btb-garden-db .info-card.requested-far .icon,
.btb-garden-db .info-card.requested-far .content h3 {
    color: #06abb1;
}

.btb-garden-db .info-card.total-app {
    background: #E6ECFF;
}

.btb-garden-db .info-card.total-app .icon,
.btb-garden-db .info-card.total-app .content h3 {
    color: #1127cd;
}

.btb-garden-db .info-card.reject {
    background: #FAE9E3;
}

.btb-garden-db .info-card.reject .icon,
.btb-garden-db .info-card.reject .content h3 {
    color: #bd180c;
}
</style>
